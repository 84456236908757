import { createIntl, createIntlCache } from 'react-intl'
import { messages } from 'app/localization'

const DEFAULT_LOCALE = 'en'

const cache = createIntlCache()

const intl = createIntl(
  {
    locale: DEFAULT_LOCALE,
    messages: messages[DEFAULT_LOCALE]
  },
  cache
)

export default intl
