import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  styled,
  Box,
  Chip,
  ChipProps,
  Divider
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import { Typography } from '@carto/react-ui'
import IconButton from '../IconButton'
import EllipsisWithTooltip from '../utils/EllipsisWithTooltip'

export type DialogHeaderProps = Omit<MuiDialogTitleProps, 'title'> & {
  title: ReactNode
  chipLabel?: ReactNode
  chipProps?: ChipProps
  onClose?: () => void
  secondaryActions?: ReactNode
  ariaLabel?: string
}

const DialogTitle = styled(MuiDialogTitle)(() => ({
  padding: 0,
  zIndex: 10
}))

const Main = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  minHeight: theme.spacing(8)
}))

const ItemsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  overflow: 'hidden'
}))

const CloseIcon = styled(CloseOutlined)(({ theme }) => ({
  color: theme.palette.text.secondary
}))

const DividerBottom = styled(Divider)(() => ({
  '+ .MuiDialogContent-root': {
    borderTop: 'none !important'
  }
}))

export function DialogHeader({
  title,
  chipLabel,
  chipProps,
  children,
  onClose,
  secondaryActions,
  'aria-label': ariaLabel,
  ...otherProps
}: DialogHeaderProps) {
  return (
    <>
      <DialogTitle component="div" {...otherProps}>
        <Main>
          <ItemsWrapper flex={1} maxWidth={0.8}>
            <Typography component="h2" variant="inherit" noWrap aria-label={ariaLabel}>
              <EllipsisWithTooltip>{title}</EllipsisWithTooltip>
            </Typography>

            {chipLabel && <Chip color="default" label={chipLabel} {...chipProps} />}
          </ItemsWrapper>

          <ItemsWrapper>
            {secondaryActions}

            {onClose && (
              <IconButton
                onClick={onClose}
                icon={<CloseIcon data-testid="CloseIcon" />}
                tooltip={<FormattedMessage id="common.button.close" />}
                tooltipPlacement="left"
              />
            )}
          </ItemsWrapper>
        </Main>

        {children}
      </DialogTitle>
      {children && <DividerBottom />}
    </>
  )
}

export default DialogHeader
