import { alpha, darken, lighten } from '@mui/material/styles'

export interface Branding {
  logoUrl?: string
  primaryColor: string
  secondaryColor: string
  appBarBackgroundColor?: string
  appBarContrastTextColor?: string
  usePrimaryColorsForAppBar: boolean
  customizedLogo?: boolean
  updated_at?: string
  disableCartoFooter?: boolean
  disableOnboarding?: boolean
  customCodeBuilder?: string
}

export const getMUIThemePropsFromBranding = (branding?: Branding) => {
  if (!branding) {
    return null
  }

  const props = {
    ...((branding.primaryColor ||
      branding.secondaryColor ||
      branding.appBarBackgroundColor ||
      branding.usePrimaryColorsForAppBar) && {
      palette: {
        ...(branding.primaryColor && {
          primary: {
            main: branding.primaryColor,
            dark: darken(branding.primaryColor, 0.2),
            light: lighten(branding.primaryColor, 0.2),
            background: alpha(branding.primaryColor, 0.08),
            relatedLight: lighten(branding.primaryColor, 0.92)
          }
        }),
        ...(branding.secondaryColor && {
          secondary: {
            main: branding.secondaryColor,
            dark: darken(branding.secondaryColor, 0.2),
            light: lighten(branding.secondaryColor, 0.2),
            background: alpha(branding.secondaryColor, 0.08),
            relatedLight: lighten(branding.secondaryColor, 0.92)
          }
        }),
        ...(branding.appBarBackgroundColor && {
          brand: {
            appBarMain: branding.usePrimaryColorsForAppBar ? branding.primaryColor : branding.appBarBackgroundColor,
            appBarContrastText: branding.appBarContrastTextColor
          }
        })
      }
    })
  }
  return props
}
