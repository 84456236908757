import settings from 'settings'
import { ResourceType } from 'features/common/types'
import { isInIframe } from 'app/utils/embedding'

export const redirectUriKey = 'redirectUri'

export function redirectToAccounts(path = '', resource?: ResourceType) {
  const accountsUrl = `${settings.accountsUrl}` + path
  const url = new URL(`${accountsUrl}`)
  url.searchParams.append(redirectUriKey, window.location.href)

  if (resource) {
    url.searchParams.append('resource', resource)
  }

  if (isInIframe) {
    window.open(url.toString(), '_blank', 'noopener,noreferrer')
  } else {
    window.location.href = url.toString()
  }
}
