export default {
  title: 'Data Observatory',
  subtitle: 'Spatial Data Catalog',
  loading: 'Loading catalog...',
  actions: {
    subscribeFree: 'Subscribe for free',
    subscribeSample: 'Access free sample',
    subscribePremium: 'Request subscription',
    subscriptionRequested: 'Cancel request',
    alreadySubscribed: 'View sample',
    accessData: 'Access data'
  },
  subscription: {
    title: 'Confirm your subscription',
    subtitle: "You're going to subscribe to the following dataset",
    terms: 'I accept the License',
    connect: 'Confirm subscription',
    request: {
      title: 'Confirm your request',
      subtitle: "You're going to request a subscription for the following dataset",
      info: 'Once you confirm your request, a <strong>CARTO team member</strong> will get in touch to give you more information and go over any questions you may have.',
      confirm: 'Confirm request'
    },
    alert: {
      public: 'Public',
      premium: 'Premium',
      sample: 'You have a sample of this dataset available in the Data Explorer.',
      active: 'Active subscription',
      subscribed: 'You have an {active} to this {type} dataset.',
      requested:
        'You have requested a subscription to this dataset. A CARTO team member will get in touch to give you more information.',
      dismiss: 'Changed your mind? <a>Dismiss it.</a>'
    },
    dismiss: {
      title: 'Dismiss request',
      message: 'Are you sure you want to cancel the subscription request? You can request it again at any time.',
      confirm: 'Yes, dismiss',
      cancel: 'No, keep it'
    }
  },
  accessSample: {
    title: 'Connect your sample',
    subtitle: "You're going to access the following sample dataset:",
    disclaimer:
      'The sample data is for trial evaluation purposes only and may differ slightly from product data. It may be used internally only for non-commercial purposes. This sample is subject to limited, non-exclusive, non-transferable, non-sublicensable and revocable rights and license to use. Any rights not expressly granted are withheld.',
    terms: 'I accept the Terms of Use',
    connect: 'Connect sample',
    sampleNotAvailable: 'Sample not available: this sample is for {dataset}',
    somethingWentWrong: 'Something went wrong'
  }
}
