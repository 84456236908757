import { Box, styled } from '@mui/material'
import Stepper from '../Stepper/Stepper'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(8),
  marginBottom: theme.spacing(2),

  '& > div': {
    flex: 1
  }
}))

export function DialogStepper({ currentStep, stepsLabels }: { currentStep: number; stepsLabels: string[] }) {
  return (
    <Root>
      <Stepper currentStep={currentStep} steps={stepsLabels} />
    </Root>
  )
}
