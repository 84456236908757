export default {
  helpAndResources: 'Help & Resources',
  buttonLabel: 'Help',
  learnMore: 'Learn more',
  questionPanel: {
    informationGathered: 'Information gathered from our documentation.',
    loader: 'Generating your answer...',
    subtitle: 'Try asking me about...',
    noResults:
      "We couldn't find anything for your question. Try asking something different or take a look at our documentation.",
    visitOurDocumentation: 'Visit our documentation',
    learnMore: 'Learn more',
    askAnything: 'Ask anything'
  },
  newsTitle: "What's new",
  newsSubtitle: 'Learn about the latest features and improvements in CARTO',
  contactUs: 'Contact us',
  sendFeedback: 'Send feedback',
  academy: 'CARTO Academy',
  documentation: 'Documentation',
  suggestedQuestions: {
    1: 'Does CARTO make any copies of our data?',
    2: 'Can I embed my non-public CARTO map in other websites?',
    3: 'Are there any optimizations to make maps perform better?',
    4: 'Is it possible to programmatically run a Workflow via API?',
    5: 'How can I add a custom icon for a specific data category in my map?'
  },
  notAvailableYet:
    'CARTO Self-Hosted does not include yet integrating our external Documentation resources. Please visit <a>docs.carto.com</a> to search the content directly'
}
