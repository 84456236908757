export default {
  header: {
    viewDataset: 'View dataset',
    accessIn: 'Access in',
    sample: 'Sample dataset',
    public: 'Public dataset',
    premium: 'Premium dataset'
  },
  accessIn: {
    checking: 'Checking access...',
    requestSuccess: 'Your request has been sent successfully'
  },
  noData: {
    titleAlt: 'No subscriptions found',
    caption: 'No subscriptions available',
    captionAlt: "There aren't data subscriptions accessible via this connection",
    content: 'Browse the Spatial Data Catalog',
    contentAlt: 'Manage your subscriptions'
  }
}
