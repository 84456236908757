import settings from 'settings'

export default {
  title: 'API Access Tokens',
  filters: {
    search: 'Search'
  },
  list: {
    alert: {
      info: 'These are API Access Tokens created by you.',
      noUsers: `Other users' tokens aren't shown in this list.`,
      link: 'Read more in the documentation'
    },
    table: {
      title: 'List of API Access Tokens',
      columns: {
        name: 'Name',
        token: 'Token',
        updatedAt: 'Last modified'
      },
      copy: 'Copy API Access Token',
      copied: 'Copied to clipboard',
      actions: {
        edit: 'View or edit credential',
        delete: 'Delete'
      }
    }
  },
  edit: {
    title: 'Edit API Access Token',
    save: 'Save changes',
    success: 'Changes saved successfully',
    error:
      'A problem occurred while editing the token. Please try again later. If the problem persists, please contact us.'
  },
  create: {
    title: 'Create new API Access Token',
    save: 'Save',
    cancel: 'Cancel',
    form: {
      expand: 'Expand more info',
      collapse: 'Collapse'
    },
    formBasic: {
      title: 'Basic information',
      info: 'Assign a unique name to this token, or let us generate one automatically.',
      name: 'Name',
      namePlaceholder: 'Unique name for you token'
    },
    apis: {
      title: 'Allowed APIs',
      info: 'Define one or more APIs that this token will be able to use.',
      maps: 'Maps API',
      sql: 'SQL API',
      lds: 'LDS API',
      imports: 'Imports API'
    },
    grants: {
      title: 'Grants',
      info: 'Define which data sources this token will be able to access.',
      grant: {
        connection: 'Connection',
        connectionPlaceholder: 'Choose a connection',
        helpText: 'Your token will use this connection to access the data',
        helpTextImportApi: 'Your token will be able to import data into this connection.',
        source: 'Allow a specific data source from this connection',
        dataExplorer: {
          title: 'Table, Tileset or Raster source',
          placeholder: 'Paste the fully qualified name or browse it',
          action: 'Browse',
          helperText: 'Paste the fully qualified name or browse it in the Data Explorer.',
          editor: {
            title: 'Add source',
            action: {
              save: 'Add source',
              cancel: 'Cancel'
            }
          }
        },
        sql: {
          title: 'SQL query source',
          placeholder: 'Use the SQL editor to write your query',
          action: 'SQL Editor',
          helperText: 'Your token will be able to use this SQL query',
          moreInfo: 'Remember you can use parameters in your queries as described in the <a>Documentation</a>',
          editor: {
            title: 'SQL editor',
            subtitle: 'Your token will be able to use this SQL query:',
            info: 'Remember you can use parameters in your queries as described in the <a>Documentation</a>',
            action: {
              save: 'Save',
              cancel: 'Cancel'
            }
          }
        },
        allSources: {
          title: 'Allow access to all sources',
          modal: {
            title: 'Allow access to all sources',
            description: 'Are you sure you want to allow access to all sources in {connection_name}?',
            info: 'This option will allow this token to access all data, current and future, available to this connection.',
            action: {
              save: 'Yes, allow',
              cancel: 'Cancel'
            }
          }
        },
        addGrant: 'Add another grant',
        removeGrant: 'Remove grant',
        allSourcesDescription: 'Allows this token to access all data available to this connection, current and future.'
      }
    },
    tokenRestrictions: {
      title: 'Token restrictions',
      info: 'Restrict which domains can make requests to the APIs using this token.',
      name: 'Allowed Referers URLs',
      namePlaceholder: `E.g.: https://carto.com/, ${settings.cartoAcademyUrl}/`,
      nameHelpText: 'Only a comma-separated list of valid URLs is accepted.'
    },
    success: 'API Access Token created successfully',
    error:
      'A problem occurred while creating the token. Please try again later. If the problem persists, please contact us.'
  },
  emptyContent: {
    title: "You don't have an API Access Token yet",
    link: 'Learn how to create API Access Tokens',
    emptySearch: 'API Access Token not found'
  },
  cards: {
    deleteDialog: {
      title: 'Delete token',
      description:
        'Are you sure you want to delete {name}? Applications and projects using this credential will stop working. This action cannot be undone.',
      info: 'All code and API requests using this token will no longer work.',
      delete: 'Yes, delete',
      deleting: 'Deleting...',
      errorMessage:
        'A problem occurred while deleting the token. Please try again later. If the problem persists, please contact us.',
      successMessage: 'API Access Token deleted'
    }
  },
  sqlEmptyModal: {
    title: "This token doesn't have a SQL source",
    description: 'Are you sure you want to leave this token without a SQL query source?',
    info: "You won't be able to use the SQL API with this token because you haven't included any SQL query grant.",
    save: 'Yes, save'
  }
}
