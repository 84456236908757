export default {
  title: 'Applications',
  total: '{count, plural, =0 {No apps} one {# app} other {# apps}}',
  loadMore: 'Load more',
  all: 'All applications',
  emptyState: {
    title: "You don't have any app yet"
  },
  edit: {
    title: 'Edit application'
  },
  featured: {
    title: 'Featured Apps',
    pretitle: 'Get inspired',
    actions: {
      livePreview: 'Live Preview',
      goToApp: 'Go to app',
      contactUs: 'Contact us',
      back: 'Back',
      done: 'Done',
      watchVideo: 'Watch video'
    }
  },
  create: {
    title: 'Application',
    common: {
      manageOptions: 'Manage options'
    },
    cancel: 'Cancel',
    private: {
      title: 'Create new application'
    },
    errors: {
      url: 'The value must be a valid HTTPS url',
      urlMultiline: 'The value must be a valid list of HTTPS urls '
    },
    formBasic: {
      title: 'Basic information',
      nameLabel: 'Name',
      namePlaceholder: 'Type a name for your application',
      descriptionLabel: 'Description',
      descriptionPlaceholder: 'Add a description in less than 120 characters',
      descriptionHelper: 'A free text description of the application. Max character count is 120.',
      appUrlLabel: 'App URL',
      appUrlPlaceholder: 'https://carto.solution',
      thumbnailUrlLabel: 'Thumbnail URL',
      thumbnailUrlHelper:
        'The URL of the thumbnail to display for the application, if none is set the default badge for this type of application will be shown. Recommended size is 150x150 pixels.',
      pinHelper: 'Decide if you want to show this app in the CARTO Homepage.'
    },
    formSharing: {
      title: 'Sharing options',
      description:
        'An app shared with a group/organization means that that group or organization will be able to view the app in Workspace. \n It doesn’t mean they can or can’t access the app. The app is responsible for managing user authentication.'
    },
    form: {
      expand: 'Expand more info',
      collapse: 'Collapse'
    },
    formProperties: {
      advancedTitle: 'Advanced Settings',
      title: 'Application Properties',
      uris: 'Application URIs',
      appTypeLabel: 'Application type',
      appTypeOptions: {
        spa: 'Single Page Application',
        non_interactive: 'Machine to Machine',
        regular_web: 'Regular Web Application'
      },
      appTypeHelper: 'The type of application will determine which settings you can configure from Workspace.',
      tokenLabel: 'Token Endpoint Authentication Method',
      tokenOptions: {
        post: 'Post',
        basic: 'Basic',
        none: 'None'
      },
      tokenHelper: 'Authentication method for the token endpoint.',
      tokenMoreInfo:
        "Defines the requested authentication method for the token endpoint. Possible values are 'None' (public application without a client secret), 'Post' (application uses HTTP POST parameters) or 'Basic' (application uses HTTP Basic)."
    },
    formAppUris: {
      appLoginLabel: 'Application Login URI',
      appLoginPlaceholder: 'https://carto.solution/login',
      appLoginMoreInfo:
        'In some scenarios, CARTO will need to redirect to your application’s login page. This URI needs to point to a route in your application that should redirect to your tenant’s <code>/authorize</code> endpoint. <a href="#" target="_blank" rel="noopener noreferrer">Learn more.</a>',
      appLoginHelper: 'The URL of the application where you will use this SPA OAuth Client.',
      allowedCallbackLabel: 'Allowed Callback URLs',
      allowedCallbackHelper:
        'List of URLs to which CARTO is allowed to redirect users after they authenticate. You can specify multiple valid URLs by comma-separating them.',
      allowedLogoutLabel: 'Allowed Logout URLs',
      allowedLogoutHelper:
        'List of URLs to which CARTO is allowed to redirect users after they logout from your application. You can specify multiple valid URLs by comma-separating them.',
      allowedWebOriginLabel: 'Allowed Web Origins',
      allowedWebOriginHelper:
        'List of URLs from where an authorization request can originate from. You can specify multiple valid URLs by comma-separating them.',
      allowedCorsLabel: 'Allowed Origins (CORS)',
      allowedCorsHelper:
        'List of URLs that will be allowed to make requests using this application. By default, all your callback URLs will be allowed. This field allows you to enter other origins if you need to. You can specify multiple valid URLs by comma-separating them.'
    },
    save: 'Save'
  },
  shareDialog: {
    confirm: 'Confirm',
    shareWithGroups: {
      notAvailable:
        "This app is shared with all users in your organization. If you're interested in sharing with certain users or groups, <link>let us know</link>.",
      title: 'Share mode'
    }
  },
  gallery: {
    searchPlaceholder: 'Search application',
    searchResults: '{number} results by "{param}"'
  },
  cards: {
    actions: {
      edit: 'Edit or view',
      share: 'Share',
      delete: 'Delete',
      pin: 'Pin to Home',
      unpin: 'Unpin'
    },
    deleteDialog: {
      title: 'Delete app',
      description: 'Are you sure you want to delete {name} from your organization?'
    },
    detailsDialog: {
      title: 'App details',
      edit: 'Edit',
      done: 'Done',
      nameLabel: 'Name',
      domainLabel: 'Domain',
      clientIdLabel: 'Client ID',
      appUrlLabel: 'App URL',
      clientSecretLabel: 'Client Secret',
      clientSecretHelper: 'The Client Secret is not base64 encoded.',
      appTypeLabel: 'Application type'
    }
  },
  introCarousel: {
    slide1: {
      pretitle: 'Site selection & Location Intelligence',
      title: 'Pinpoint the whitespace you couldn’t see before',
      content:
        'Visualize store catchment or trade areas to understand where your key segments of customers are in relation to your store network & competitor locations.',
      button: {
        label: 'Discover CARTO for Site Selection'
      }
    },
    slide2: {
      pretitle: 'Geomarketing',
      title: 'Get OOH location insights faster',
      content:
        'Using location data can enable faster, more up-to-date insights on how audiences are changing, increasing campaign ROI considerably in the short-term.',
      button: {
        label: 'Discover CARTO for OOH'
      }
    }
  }
}
