import { Box } from '@mui/material'
import Step from './Step'

function Stepper({ steps, currentStep }: { steps: string[]; currentStep: number }) {
  return (
    <Box display="flex">
      {steps.map((stepLabel, idx) => (
        <Step currentStep={currentStep} step={idx + 1} key={idx} label={stepLabel} />
      ))}
    </Box>
  )
}

export default Stepper
