export default {
  property: {
    weight: 'Weight',
    label: 'Label',
    fillColor: 'Fill color',
    color: 'Color',
    coverage: 'Coverage',
    strokeColor: 'Stroke color',
    radius: 'Radius',
    outline: 'Outline',
    stroke: 'Stroke',
    density: 'Density',
    height: 'Height'
  },
  layer: {
    type: {
      'source-error': 'Source error',
      mvt: 'Tileset'
    },
    propertyBasedOn: '{property} based on'
  },
  layerConfiguration: {
    defaultDescription: `Apply a {property} based on column's values`
  },
  scale: {
    strokeColorScale: 'Stroke color Scale',
    heightScale: 'Height Scale'
  },
  layerVisConfigs: {
    heightDescription: 'Activate 3D view on the "Map View" toolbar above the map'
  },
  layerVisibilityByZoom: {
    title: 'Visibility by zoom level'
  }
}
