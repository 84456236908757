import ReactDOM from 'react-dom'
import { useState, useEffect } from 'react'
import { RawIntlProvider } from 'react-intl'
import { ThemeProvider, CssBaseline } from '@mui/material'

import intl from 'app/localization/intl'
import theme from 'features/common/ui/theme/cartoTheme'
import SplashLoading from 'features/common/ui/Loaders/SplashLoading'
import workspaceVectorUrl from 'assets/img/carto-logo-positive.svg'

import BuilderStorageAccessPrompt from 'features/builder/ui/BuilderPublic/BuilderStorageAccessPrompt'

const isChrome = navigator.userAgent.includes('Chrome')

const hasStorageAccessApi = 'hasStorageAccess' in document && 'requestStorageAccess' in document

export function StorageAccessRequestUi({ onGranted }) {
  const [requiresStoragePermission, setRequiresStoragePermission] = useState<boolean | undefined>(undefined)
  const [storagePermissionDenied, setStoragePermissionDenied] = useState<boolean>(false)

  useEffect(() => {
    document
      .hasStorageAccess()
      .then((hasAccess) => {
        if (hasAccess) {
          onGranted()
        } else {
          setRequiresStoragePermission(true)
        }
      })
      .catch((e) => {
        setRequiresStoragePermission(true)
        console.error('StorageAccessRequestUi hasStorageAccess failed', e)
      })
  }, [onGranted])

  const handleRequestStorageAccess = () => {
    const requestStorageAccessPromise = isChrome
      ? // chrome has this weird, non-standard specifier for used storages in API
        // https://privacycg.github.io/saa-non-cookie-storage/#use-cases
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (document.requestStorageAccess as any)({ all: true })
      : // https://developer.mozilla.org/en-US/docs/Web/API/Document/requestStorageAccess
        // https://webkit.org/blog/11545/updates-to-the-storage-access-api/
        document.requestStorageAccess()
    requestStorageAccessPromise
      .then((context) => {
        if (isChrome && context?.localStorage) {
          try {
            Object.defineProperty(window, 'localStorage', {
              value: context?.localStorage,
              writable: true
            })
          } catch (e) {
            console.error('AuthenticatedRouteForIframes unable to overwrite localStorage failed', e)
            return
          }
        }
        onGranted()
      })
      .catch((e) => {
        setStoragePermissionDenied(true)
        console.error('StorageAccessRequestUi', e)
      })
  }

  // render user interaction to check for access
  if (requiresStoragePermission === undefined) {
    return <SplashLoading imageUrl={workspaceVectorUrl} />
  }

  return (
    <BuilderStorageAccessPrompt
      onRequestAccess={handleRequestStorageAccess}
      accessWasDenied={storagePermissionDenied}
    />
  )
}

function MinimalAppContext({ children }) {
  return (
    <RawIntlProvider value={intl}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </RawIntlProvider>
  )
}

export function ensureUnpartitionedStorageAccessIfNeeded(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    if (!hasStorageAccessApi) {
      resolve()
      return
    }

    const rootElement = document.getElementById('root') as HTMLElement
    if (!rootElement) reject(new Error('no root element'))

    const handleGranted = () => {
      ReactDOM.unmountComponentAtNode(rootElement)
      resolve()
    }
    ReactDOM.render(
      <MinimalAppContext>
        <StorageAccessRequestUi onGranted={handleGranted} />
      </MinimalAppContext>,
      rootElement
    )
  })
}
