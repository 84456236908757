export default {
  warning: {
    title: 'You have exceeded several quota limits for your team organization. Some actions may be limited.',
    button: 'More info'
  },
  modal: {
    title: 'Organization limits',
    subtitle:
      'Your current beta organization has certain quota limits described below. These limits are shared by all users in the organization. If you need to increase these limits, please let us know by clicking the option below.'
  },
  generic: {
    message: 'You have reached the {quota} quota ({current} of {limit}).',
    mapsQuota: 'maps',
    mapLoadsQuota: 'map loads',
    connectionsQuota: 'connections',
    appsQuota: 'apps',
    usersQuota: 'users',
    tokensQuota: 'tokens'
  },
  usersByRole: {
    message: 'You have used all {role} users ({limit}) available in your current plan.',
    viewer: 'viewer',
    editor: 'editor'
  },
  wouldExceedQuotaModal: {
    upgrade: 'Upgrade',
    currentPlan: 'CURRENT PLAN'
  }
}
