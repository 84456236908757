import PropTypes from 'prop-types'
import { Dialog as MuiDialog, DialogProps as MuiDialogProps } from '@mui/material'

import { DialogSize, DialogPaper } from './DialogPaper'

export interface DialogProps extends MuiDialogProps {
  /**
   * Dialog size as predefines in DS
   *
   * Supported values: `'small'` | `'medium'` | `'large'` | `'xlarge'`
   */
  size?: DialogSize

  /**
   * Use for debugging purposes, like in storybook to render Dialog in current DOM position without any portal.
   */
  nonModal?: boolean

  /**
   * Overrides height defined in sizes. To make height adapted to content (fluid) 'auto' or a fixed value
   * */
  height?: 'auto' | string

  /**
   * Overrides width defined in sizes. To make width adapted to content (fluid) 'auto' or a fixed value
   * */
  width?: 'auto' | string

  /**
   * For Dialogs wrapped in a form element, as they need specific styling to work properly
   * */
  hasForm?: boolean
}

export function Dialog({
  nonModal,
  children,
  size = 'small',
  PaperProps,
  fullScreen,
  height,
  width,
  hasForm,
  ...props
}: DialogProps) {
  if (nonModal) {
    return (
      <DialogPaper size={size} fullScreen={fullScreen} height={height} width={width} hasForm={hasForm}>
        {children}
      </DialogPaper>
    )
  }
  return (
    <MuiDialog
      PaperComponent={DialogPaper}
      PaperProps={{ ...PaperProps, size, fullScreen, height, width, hasForm }}
      fullScreen={fullScreen}
      role="section"
      {...props}
    >
      {children}
    </MuiDialog>
  )
}

Dialog.propTypes = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...(MuiDialog as any).propTypes,
  size: PropTypes.string,
  nonModal: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  hasForm: PropTypes.bool
}
