import { styled } from '@mui/material'
import { Box, CircularProgress } from '@mui/material'

const LoaderLayout = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

function LoadingContent() {
  return (
    <LoaderLayout m={3}>
      <CircularProgress size={24} />
    </LoaderLayout>
  )
}

export default LoadingContent
