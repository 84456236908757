import { loadConfig } from 'settings'
import { ensureUnpartitionedStorageAccessIfNeeded } from 'app/ui/EmbeddedStorageAccess'
import { isInIframe } from 'app/utils/embedding'

const boostrapApp = async () => {
  await loadConfig()

  const needsUnpartitionedStorageAccess = isInIframe && window.location.pathname.startsWith('/viewer')

  if (needsUnpartitionedStorageAccess) {
    await ensureUnpartitionedStorageAccessIfNeeded()
  }

  await import('./ReactApp')
}

boostrapApp()
