export default {
  actions: {
    map: {
      docs: 'documentation',

      label: 'Or create your first map',
      new: 'Create map'
    },
    workflow: {
      new: 'Create workflow',
      label: 'Or create your first workflow'
    },
    lastMaps: {
      title: 'Recent maps',
      all: 'View all'
    },
    lastWorkflows: {
      title: 'Recent workflows',
      all: 'View all'
    }
  },
  gettingStarted: {
    title: 'Introducing the new CARTO platform',
    subtitle: 'Getting Started'
  },
  blog: {
    title: "What's new",
    subtitle: 'From our blog',
    visit: 'Visit blog'
  },
  onboarding: {
    welcome: {
      title: 'Welcome to CARTO',
      content:
        "Get started by creating stunning maps and bringing your spatial data to life! Enrich your analysis with our Data Observatory, build powerful apps, and more — you'll be soon turning your location data into powerful insights.",
      buttons: {
        gettingStarted: {
          title: 'Getting started',
          description: 'Bring your data and create your first map'
        },
        accessTutorials: {
          title: 'Access tutorials',
          description: 'Solve interesting use cases following our guides'
        },
        documentation: {
          title: 'Check our documentation',
          description: 'Unlock the full potential of CARTO technology'
        }
      }
    },
    checklist: {
      title: 'Getting started',
      completed: 'completed',
      skipLink: "I'm ready, skip onboarding",
      confirmation: {
        title: 'Congrats! You’re an expert',
        content:
          'Discover the most advanced spatial data analysis and visualizations right in the CARTO workspace.<br></br>Visit <a>our documentation</a> to learn more.',
        button: 'Close'
      },
      tasks: {
        checkDemoMap: 'Check how one of our demo maps was built',
        buildFirstMap: 'Build your first map with the help of our tutorials',
        browseDataExplorer: 'Bring and explore your data',
        browseDoCatalog: 'Explore the Data Observatory ',
        browseAppGallery: 'Get inspired with our App Gallery',
        buildFirstWorkflow: 'Build your first workflow following our tutorials'
      }
    },
    maps: {
      title: 'Start with your spatial analysis'
    },
    workflows: {
      title: 'Start creating workflows'
    },
    dataWarehouse: {
      title: 'Connect your data warehouse',
      content:
        'Seamlessly interact with data hosted in your cloud data warehouse, eliminating ETL complexity and any limits on scalability.',
      button: 'Create new connection'
    },
    import: {
      title: 'Upload local files',
      content: 'Upload your own datasets and use them to create new spatial analysis.',
      button: 'Import your data'
    }
  },
  noCartoDW: {
    getStarted: 'GET STARTED'
  },
  datasets: {
    title: 'Recently used data',
    explore: 'Explore data',
    import: 'Import dataset'
  },
  apps: {
    card: {
      siteSelection: 'Site selection',
      goToApp: 'Go to app'
    }
  }
}
