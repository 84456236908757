import { styled } from '@mui/material'
import { Alert as CARTOAlert, AlertProps } from '@carto/react-ui'

const Alert = styled(CARTOAlert)(({ theme }) => ({
  minHeight: 'auto',
  padding: 0,
  backgroundColor: 'transparent',

  '& .MuiTypography-root': {
    color: theme.palette.text.secondary,

    a: {
      color: theme.palette.primary.main
    }
  },
  '& .MuiAlertTitle-root': {
    color: theme.palette.text.primary
  }
}))

export function DialogAlert({ children, severity = 'neutral', ...otherProps }: AlertProps) {
  return (
    <Alert severity={severity} {...otherProps}>
      {children}
    </Alert>
  )
}
