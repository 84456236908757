export default {
  tooltip: {
    running: 'Cancel process',
    error: 'View error info',
    clear: 'Clear completed jobs'
  },
  geocode: {
    status: {
      success: 'Table geocoded successfully',
      failure: 'Geocoding error. {link}',
      running: 'Geocoding table…'
    },
    tooltip: {
      success: 'View geocoded table'
    },
    error: {
      title: 'Geocoding table error',
      general: 'It seems that there was a problem geocoding the table and your request could not be completed.',
      info: 'Error info'
    },
    cancel: {
      title: 'Cancel processing job',
      question: 'Do you want to cancel this process?',
      confirm: 'Yes, cancel process',
      continue: 'No, continue'
    },
    customQuery: 'Custom Geocode creation'
  },
  optimize: {
    status: {
      success: 'Table optimized successfully',
      failure: 'Optimization error. {link}',
      running: 'Optimizing table…'
    },
    tooltip: {
      success: 'View optimized table'
    },
    error: {
      title: 'Table optimization error',
      general: 'It seems that there was a problem optimizing the table and your request could not be completed.',
      info: 'Error info'
    },
    cancel: {
      title: 'Cancel processing job',
      question: 'Do you want to cancel this process?',
      confirm: 'Yes, cancel process',
      continue: 'No, continue'
    },
    customQuery: 'Custom Optimization creation'
  },
  export: {
    tab: 'Exports',
    status: {
      success: 'Dataset exported successfully',
      failure: 'Dataset export error. {link}',
      failureInline: 'Dataset export error',
      running: 'Exporting dataset…'
    },
    tooltip: {
      success: 'View exported dataset'
    },
    error: {
      title: 'Dataset export error',
      general: 'It seems that there was a problem exporting the dataset and your request could not be completed.',
      info: 'Error info',
      access:
        "It seems your connection doesn't have enough permissions to export a dataset at the output destination (or it doesn't exist). Read more in our {documentationLink}"
    },
    cancel: {
      title: 'Cancel exporting job',
      question: 'Do you want to cancel this process?',
      confirm: 'Yes, cancel process',
      continue: 'No, continue'
    },
    customQuery: 'Custom export creation'
  },
  layerExport: {
    status: {
      success: 'Layer exported successfully.',
      failure: 'Layer export error. <readMoreLink>Read more</readMoreLink>',
      canceled: 'Canceled',
      running: 'Exporting layer…'
    },
    download: {
      title: 'Download data',
      message:
        'Layer <strong>{name}</strong> exported successfully. Download the files by clicking on the links below.',
      noFilesInfo:
        'The export didn’t produce any file. Please make sure that the layer contains data in the current viewport.'
    },
    error: {
      title: 'Layer export error',
      message: 'It seems that there was a problem exporting the layer and your request could not be completed.'
    },
    cancel: {
      title: 'Cancel export',
      question: 'Do you want to cancel export process of <strong>{name}</strong>?',
      confirm: `Yes, cancel export`,
      continue: 'No, continue',
      notificationSuccess: 'Export canceled successfully.',
      notificationFailed: 'Failed to cancel export.'
    }
  },
  activityDataExport: {
    status: {
      success: 'Data exported successfully. <downloadLink>Download</downloadLink>',
      failure: 'Data export error. <readMoreLink>Read more</readMoreLink>',
      canceled: 'Canceled',
      running: 'Exporting activity data…'
    },
    download: {
      title: 'Download activity data',
      categories: {
        activity: 'Activity logs and API usage',
        additionalData: 'Additional data'
      },
      info: 'Find a complete reference and examples on using this data in our <documentationLink>documentation</documentationLink>.'
    },
    error: {
      title: 'Activity data export error',
      message: 'It seems that there was a problem exporting the data and your request could not be completed.'
    },
    cancel: {
      title: 'Cancel export',
      question: 'Do you want to cancel export process of <strong>{name}</strong>?',
      confirm: `Yes, cancel export`,
      continue: 'No, continue',
      notificationSuccess: 'Export canceled successfully.',
      notificationFailed: 'Failed to cancel export.'
    }
  },
  download: {
    parts: `{count} {count, plural,
      one {part}
      other {parts}
    }`,
    availableUntil: 'Available until: {date} at {hour}'
  },
  tilesets: {
    processingJobs: 'Processing jobs',
    error: {
      title: 'Tileset creation error',
      info: 'Error info',
      general: 'It seems that there was a problem creating the tileset and your request could not be completed.',
      access:
        "It seems your connection doesn't have enough permissions to create a table at the output destination (or it doesn't exist). Read more in our {documentationLink}",
      documentationLink: 'documentation'
    },
    query: 'Query',
    cancel: {
      title: 'Cancel processing job',
      question: 'Do you want to cancel this process?',
      confirm: 'Yes, cancel process',
      continue: 'No, continue'
    },
    nojobs: 'No jobs',
    running: 'Running:',
    tooltip: {
      success: 'View tileset'
    },
    status: {
      success: 'Tileset created successfully',
      failure: 'Tileset creation error. Read more',
      running: 'Creating tileset…'
    },
    customQuery: 'Custom Tileset creation'
  },
  datasets: {
    tooltip: {
      success: 'View dataset'
    },
    status: {
      success: 'Imported successfully',
      failure: 'Dataset creation error. Read more',
      failureInline: 'Import error',
      running: 'Importing...',
      runningInline: 'Importing to'
    },
    error: {
      title: 'Dataset creation error'
    }
  },
  tabs: {
    imports: 'IMPORTS',
    na: 'N/A'
  },
  enrich: {
    status: {
      success: 'Table enriched successfully',
      failure: 'Enrich error. Read more',
      running: 'Enriching table…',
      runningInline: 'Enriching',
      failureInline: 'Enrich error'
    },
    tooltip: {
      success: 'View enriched table'
    },
    error: {
      title: 'Enriching table error',
      general: 'It seems that there was a problem enriching the table and your request could not be completed.',
      info: 'Error info',
      access:
        "It seems your connection doesn't have enough permissions to create a table at the output destination (or it doesn't exist). Read more in our {documentationLink}"
    },
    cancel: {
      title: 'Cancel data enrichment',
      question: 'Do you want to cancel data enrichment process?',
      confirm: 'Yes, cancel process',
      continue: 'No, continue'
    },
    customQuery: 'Custom Enrich creation'
  },
  import: {
    cancel: {
      title: 'Cancel data import',
      question: 'Do you want to cancel import process?',
      confirm: 'Yes, cancel import',
      continue: 'No, continue'
    }
  },
  atInstallation: {
    status: {
      success: 'Completed',
      failure: 'There was a problem during the installation process.',
      running: 'Installing'
    }
  },
  customQuery: 'Custom query'
}
