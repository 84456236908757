import { createTheme } from '@mui/material'
import { theme as originalCartoTheme, cartoThemeOptions } from '@carto/react-ui'
import { Branding, getMUIThemePropsFromBranding } from 'features/branding/models/Branding'
import cartoLogoDot from 'assets/img/carto-logo-dot.svg'

export function getCustomTheme(branding?: Branding) {
  const custom = branding ? getMUIThemePropsFromBranding(branding) : {}
  const customOptions = {
    palette: {
      ...cartoThemeOptions.palette,
      ...custom?.palette
    },
    components: {
      ...cartoThemeOptions.components,
      MuiCssBaseline: {
        styleOverrides: {
          'body[data-dragging="true"]': {
            cursor: 'col-resize'
          }
        }
      }
    }
  }
  return createTheme(originalCartoTheme, customOptions)
}

const theme = getCustomTheme()

export function getDefaultBrandingFromTheme({ theme }) {
  const defaultBranding: Branding = {
    logoUrl: cartoLogoDot,
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.secondary.main,
    usePrimaryColorsForAppBar: false,
    appBarBackgroundColor: theme.palette.brand.navyBlue,
    appBarContrastTextColor: theme.palette.primary.contrastText,
    disableCartoFooter: false,
    disableOnboarding: false
  }
  return defaultBranding
}

export const defaultBranding = getDefaultBrandingFromTheme({ theme })

/** Colors not existing in official palette DS colors. used in designs but not yet available from Carto Theme */
export const extraColors = {
  default: {
    // (Material Red 700) · WCAG AA – 4.72:1
    red700: '#D32F2F',

    // (Material Purple 500) · WCAG AA – 5.97:1
    purple500: '#9C27B0',

    // (Material Teal 700) · WCAG AA – 5.04:1
    teal700: '#00796b',

    // (Custom) · WCAG AA – 4.94:1
    custom: '#896724',

    // (Material Blue 800) · WCAG AA – 5.44:1
    blue800: '#1565c0',

    // (Material Blue 800) 20% #1565c0
    blue800_20: 'rgba(21, 101, 192, 0.2)'
  },
  material: {
    // (Material Purple 200) · WCAG AAA 7.39:1
    purple200: '#ce93d8',

    // (Material Teal 200) · WCAG AAA 9.47:1
    teal200: '#80cbc4',

    // (Material Blue 200) · WCAG AAA 10.1:1
    blue200: '#90CAF9',

    // (Material Red A100) · WCAG AAA 7.74:1
    redA100: '#ff8a80',

    // (Material Orange 200) · WCAG AAA 11.95:1
    orange200: '#FFCC80'
  }
}

export default theme
